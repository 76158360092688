import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfileBanner from "../Images/banner.png";
import profileImg from "../Images/profile_icon.png";
import api from "../Components/apiService";

function EmailConfirmation() {
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const navigate = useNavigate(); // Use useNavigate hook to navigate
    const { token } = useParams(); // Get the token from the URL

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await api.post(`user/confirm`, {token: token});
                if (response.success === true) {
                    setConfirmationMessage("Thank you! Your email has been confirmed.");
                } else {
                    setConfirmationMessage("Email confirmation failed. Please try again.");
                }
            } catch (error) {
                console.error("Error confirming email:", error);
                setConfirmationMessage("Email confirmation failed. Please try again.");
            }
        };

        confirmEmail();
    }, [token]);

    const goToHomePage = () => {
        navigate("/"); // Use navigate function to redirect to the home page
    };

    return (
        <>
            <div className="container-fluid banner-profile p-0">
                <div className="profile-banner">
                    <img src={ProfileBanner} alt="Banner" />
                </div>
                <div className="container-fluid profile-container">
                    <div className="container-xs">
                        <div className="profile-info">
                            <div className="profile-img">
                                <img src={profileImg} alt="Profile" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main className="container-fluid mt-3">
                <div className="container-xs">
                    <div className="row">
                        <div className="col-md-8 pt-4">
                            <div className="base-card mt-3">
                                <h3>Email Confirmation</h3>
                                <div className="mt-3">
                                    <p>{confirmationMessage}</p>
                                </div>
                                <button className="btn btn-1 mt-3" onClick={goToHomePage}>
                                    Go to Home Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default EmailConfirmation;
