import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import { useNavigate, Link } from "react-router-dom";
import api from "./apiService"; // Assuming you've structured it this way
import styled from "styled-components";

const Voting = ({ initialUpvotes, initialDownvotes, type, id, showLogin, upVoted, downVoted }) => {
  const [upvotes, setUpvotes] = useState(initialUpvotes);
  const [downvotes, setDownvotes] = useState(initialDownvotes);
  const [userVote, setUserVote] = useState(null); // "up", "down" or null

  const votingPower = getUserVotingPower(); // assuming this is fetched only once

  useEffect(() => {
    // fetch user's vote status for this post/comment
    // and set userVote based on the fetched data
  }, [id]);

  
function handleVoteClick(element) {

  const isUpvote = element.classList.contains('fa-up');
  const votingPower = getUserVotingPower();
  console.log('Hello');

  let footer;
  // Determine if it's a comment or post and get the respective ID
  if (type == 'postID') {
      footer =  element.closest('.postID-footer');
  } else  {
    footer =  element.closest('.commentID-footer');
  }


  // Determine which footer we're working with
  const upvotesSpan = footer.querySelector('.up-votes');
  const score = footer.querySelector('.score');
  const downvotesSpan = footer.querySelector('.down-votes');
  const otherVotingIcon = isUpvote ? footer.querySelector('.fa-down') : footer.querySelector('.fa-up');
  console.log(score);
  // Construct request data
  let requestData = {
      [type]: id,
      value: isUpvote ? 1 : -1
  };


  if (element.classList.contains('fa-up-on') || element.classList.contains('fa-down-on')) {
      deleteVote(requestData);
      if (isUpvote) {
          upvotesSpan.textContent = Number(upvotesSpan.textContent) - 1;
          score.textContent = Number(score.textContent) - votingPower;
      } else {
          downvotesSpan.textContent = Number(downvotesSpan.textContent) - 1;
          score.textContent = Number(score.textContent) + votingPower;
      }
      element.classList.remove('fa-up-on', 'fa-down-on');
  } else {
      createVote(requestData);
      if (isUpvote) {
          upvotesSpan.textContent = Number(upvotesSpan.textContent) + 1;
          score.textContent = Number(score.textContent) + votingPower;

          // Check if the user previously downvoted, if so adjust the downvotes
          if (otherVotingIcon.classList.contains('fa-down-on')) {
              downvotesSpan.textContent = Number(downvotesSpan.textContent) - 1;
              score.textContent = Number(score.textContent) + votingPower;
              otherVotingIcon.classList.remove('fa-down-on');
          }
      } else {
          downvotesSpan.textContent = Number(downvotesSpan.textContent) + 1;
          score.textContent = Number(score.textContent) - votingPower;

          // Check if the user previously upvoted, if so adjust the upvotes
          if (otherVotingIcon.classList.contains('fa-up-on')) {
              upvotesSpan.textContent = Number(upvotesSpan.textContent) - 1;
              score.textContent = Number(score.textContent) - votingPower;
              otherVotingIcon.classList.remove('fa-up-on');
          }
      }
      element.classList.add(isUpvote ? 'fa-up-on' : 'fa-down-on');
  }
}

  

  return (
    <Container className={`${type}-footer`} data-id={id}>
      <div className="icons">
        <span className="score">{upvotes - downvotes}</span>
      </div>
      <div className="icons">
        <i
          className={`voting fal fa-up ${upVoted}`}
          onClick={(e) => handleVoteClick(e.target)}
        ></i>
        <span className="up-votes">{upvotes}</span>
      </div>
      <div className="icons">
        <i
          className={`voting fal fa-down ${downVoted}`}
          onClick={(e) => handleVoteClick(e.target)}
        ></i>
        <span className="down-votes">{downvotes}</span>
      </div>
    </Container>  
  );
};

// Your utility functions remain mostly unchanged:
function isLoggedIn() {
  const currentUserIDInput = document.getElementById("currentUserID");
  const currentUserIDValue = currentUserIDInput.value;

  return currentUserIDValue !== "";
}

function getUserVotingPower() {
  const currentUserVotingPowerInput = document.getElementById(
    "currentUserVotingPower"
  );
  const currentUserVotingPowerValue = Number(currentUserVotingPowerInput.value);

  return currentUserVotingPowerValue || 1; // Default to 1 if not specified
}

async function createVote(data) {
  try {
    const response = await api.post("vote/create", data);
    console.log(response);
  } catch (error) {
    console.error("Error creating vote:", error);
  }
}

async function deleteVote(data) {
  try {
    const response = await api.post("vote/delete", data);
    console.log(response);
  } catch (error) {
    console.error("Error deleting vote:", error);
  }
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;
  > .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.2rem 0.8rem;
    border-radius: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
    &:hover {
      color: #5865f2;
    }
    > i {
      font-size: 2rem;
    }
  }
`;

export default Voting;
