import React, { useEffect, useState } from "react";
import RecentPosts from "../Components/RecentPosts";
import { useParams } from 'react-router-dom';
import BannerImg from "../Images/profile_icon.png";
import ProfileBanner from "../Images/banner.png";
import profileImg from "../Images/profile_icon.png";
import Report from "../Components/Modals/Report";
import { useNavigate } from 'react-router-dom';
import api from "../Components/apiService";

function EditProfile() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [emailMessage, setEmailMessage] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`user/get`);
        setCurrentUser(response);
        setCurrentEmail(response.email); 
      } catch (error) {
        console.error("Fetching user failed:", error);
      }
    };

    fetchUser();
  }, []);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    if (newPassword !== rePassword) {
      setMessage("Passwords don't match");
      return;
    }

    if (!newPassword || !rePassword) {
      setMessage("Please fill in both fields");
      return;
    }

    try {
      const response = await api.post("user/update", {
        password: newPassword,
      });

      if (response.successful === 1) {
        setMessage("Password Updated");
        setNewPassword('');
        setRePassword('');
      } else if (response.message) {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Failed to update password");
    }
  };

  const handleEmailUpdate = async (event) => {
    event.preventDefault();
    if (!validateEmail(currentEmail)) {
      setEmailMessage("Invalid email");
      return;
    }

    if (!currentEmail) {
      setEmailMessage("Email field is empty");
      return;
    }

    try {
      const response = await api.post("user/update", {
        email: currentEmail,
      });

      if (response.successful === 1) {
        setEmailMessage("Email Updated");
      } else if (response.message) {
        setEmailMessage(response.message);
      }
    } catch (error) {
      setEmailMessage("Failed to update email");
    }
  };

  return (
    <>
      <div className="container-fluid banner-profile p-0">
        <div className="profile-banner">
          <img src={ProfileBanner}></img>
          {/* <button className="btn btn-3">
            <i className="fas fa-pen"></i>
          </button> */}
        </div>
        <div className="container-fluid profile-container">
          <div className="container-xs">
            <div className="profile-info">
              <div className="profile-img">
                <img
                  src={profileImg}
                  alt=""
                ></img>
                {/* <button className="btn btn-3 xs">
                  <i className="fas fa-pen"></i>
                </button> */}
              </div>
              <div className="profile-name">{currentUser.username}</div>
              {/* <button className="btn btn-1 ms-auto">
                <i className="fal fa-message me-2"></i>Message
              </button> */}
              {/* <button className="btn btn-2 report-icon" onClick={() => showReportModal(true)}>
                <i className="fal fa-flag me-2"></i> <span>Report</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <main className="container-fluid mt-3">
        <div className="container-xs">
          <div className="row">
            <div className="col-md-8 pt-4">
              <div className="base-card mt-3">
                <h3>Edit Profile</h3>
                <form className="edit-profile-form">
                <p>Change Password</p>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={`f-control ${message ? 'error-border' : ''}`}>
                        <label>
                          <i className="fal fa-key"></i>
                        </label>
                        <input
                          type="password"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          autoComplete="off"
                        ></input>
                        <div className="border"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={`f-control ${message ? 'error-border' : ''}`}>
                        <label>
                          <i className="fal fa-key"></i>
                        </label>
                        <input
                          type="password"
                          placeholder="Retype Password"
                          value={rePassword}
                          onChange={(e) => setRePassword(e.target.value)}
                          autoComplete="off"
                        ></input>
                        <div className="border"></div>
                      </div>
                    </div>
                    <div className="col-md-4 my-auto">
                      <button className="btn btn-1" onClick={handlePasswordUpdate}>Change Password</button>
                    </div>
                    {message && (
                        <div className="col-md-12" style={{ color: message === "Password Updated" ? 'green' : 'red' }}>
                          {message}
                        </div>
                      )}
                  </div>
                  <p>Update Email</p>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={`f-control ${emailMessage ? 'error-border' : ''}`}>
                        <label>
                          <i className="fal fa-envelope"></i>
                        </label>
                        <input
                            type="email"
                            placeholder="Current Email"
                            value={currentEmail || ''}
                            onChange={(e) => setCurrentEmail(e.target.value)}
                        ></input>
                        <div className="border"></div>
                      </div>
                    </div>
                    <div className="col-md-4 my-auto">
                     <button className="btn btn-1" onClick={handleEmailUpdate}>Update Email</button>
                    </div>
                    {emailMessage && (
                        <div className="col-md-12" style={{ color: emailMessage === "Email Updated" ? 'green' : 'red' }}>
                          {emailMessage}
                        </div>
                      )}
                  </div><br />
                  <p> Invitation function will be activated once we have 10,000+ members.</p>
                  {/* <p>Invite User</p>
                  <div className="base-card about-alert mt-3">
                    <h3><strong>Invitation & Ban Policy: Read Carefully</strong></h3>

                    <p><strong>1. Direct Invitation Consequences:</strong> 
                    If you invite someone and they get banned, this not only affects them but also impacts you and everyone you've directly invited. For instance, if you invite Amy and she gets banned, both you and Amy will lose your accounts.</p>

                    <h3><strong>2. Chain Invitation Consequences:</strong></h3>
                    <p>
                    It goes beyond just your direct invitations. Let's say Amy, whom you invited, invites Bob. If Bob gets banned, you will receive a 'strike,' even though you didn't invite Bob directly.
                    </p>

                    <h3><strong>3. The 10% Rule:</strong></h3>
                    <p>
                    If a total of 10% of people either directly invited by you or indirectly (like Bob in the example above) get banned, you will also be banned and lose your account. So, if you've invited 10 people directly, and they've invited 90 others combined, and 10 of these 100 people get banned, your account will be terminated.
                    </p>

                    <h3><strong>4. No Refunds:</strong></h3>
                    <p>
                      No refunds will be given.
                    </p>

                    <p>
                    It's essential to be highly selective and cautious about who you invite. The ripple effects of a ban extend far and wide within your invitation circle.
                    </p>

                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="f-control">
                        <label>
                          <i className="fal fa-user-plus"></i>
                        </label>
                        <input
                          type="email"
                          placeholder="Recipient's Email"
                        ></input>
                        <div className="border"></div>
                      </div>
                    </div>
                    <div className="col-md-4 my-auto">
                      <button className="btn btn-1">Send Invitation</button>
                    </div>
                  </div> */}
                </form>
              </div>
              {/* <div className="base-card mt-3">
                <h3>Users Invited</h3>
                <div className="edit-table">
                <table>
                  <thead>
                    <tr>
                      <th>Date Sent</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Username</th>
                      <th>Reports</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>06/28/2023</td>
                      <td>user10@example.com</td>
                      <td>
                        <div className="genre genre-3">Rejected</div>
                      </td>
                      <td>Not Available</td>
                      <td>
                        <div className="genre genre-1">Conduct</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div> */}
            </div>
            <div className="col-md-4 pt-4">
              <div className="base-card post-profile mt-3">
                <div className="top-background">
                  <img src={ProfileBanner} alt="Profile Banner"></img>
                </div>
                <div className="post-profile-header">
                  <div className="profile-img">
                    <img src={profileImg} alt="Profile"></img>
                  </div>
                  <div className="profile-name">{currentUser && currentUser.username}</div>
                </div>
                <div className="post-profile-about">
                  <p>{currentUser && currentUser.bio}</p>
                  <span>Joined On {currentUser && currentUser.dateStamp}</span>
                </div>
                <div className="post-profile-stats">
                  <div className="icons">
                    <i className="fas fa-fire"></i>
                    Voting Power : {currentUser && currentUser.votingPower}
                  </div>
                  <div className="icons">
                    <i className="fas fa-user-plus"></i>
                    Invited Users : {currentUser && currentUser.invitedUsers}
                  </div>
                </div>
              </div>
              {/* <RecentPosts /> */}
            </div>
          </div>
        </div>
      </main>
      {/* {reportModal ? (
        <Report reportModal={reportModal} showReportModal={showReportModal} />
      ) : (
        <></>
      )} */}
    </>
  );
}

export default EditProfile;
