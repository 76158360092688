import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Link, { useNavigate } from "react-router-dom";

function RecentPosts() {
  {
    /* Fetch Recent Posts from API and store in the State */
  }
  const [recentPosts, setRecentPosts] = useState();
  const getRecentPosts = async () => {
    const response = await fetch(
      "https://json.extendsclass.com/bin/20fb879fac08"
    ).then((response) => response.json());

    // update the state
    setRecentPosts(response);
  };
  useEffect(() => {
    getRecentPosts();
  }, []);

  const navigate = useNavigate();

  return (
    <Container className="base-card recent-posts mt-3">
      <span>Recent Posts</span>
      {recentPosts &&
        recentPosts.map((recentPost, i) => (
          <React.Fragment key={recentPost.id}>
            <RecentPost
              className="recent-post"
              href={
                `/post/` +
                recentPost.id +
                `/` +
                recentPost.title
                  .toLowerCase()
                  .replace(/ /g, "-")
                  .replace(/[^\w-]+/g, "")
              }
            >
              {/* <div className="post-title">{recentPost.title}</div>
              <div className="stats">
                <div className={`genre ${recentPost.genre_class}`}>
                  {recentPost.genre}
                </div>
                <div className="dot"></div>
                <div className="stats-icon">
                  <i className="fas fa-caret-up"></i> {recentPost.upvotes}
                </div>
                <div className="dot"></div>
                <div className="stats-icon">
                  <i className="fas fa-comment"></i> {recentPost.comments}
                </div>
                <div className="dot"></div>
                <div className="stats-icon">
                  <i className="fas fa-clock"></i> {recentPost.date}
                </div>
              </div> */}
            </RecentPost>
            {i == recentPosts.length - 1 ? (
              <></>
            ) : (
              <div className="bar"></div>
            )}
          </React.Fragment>
        ))}
    </Container>
  );
}

const Container = styled.div`
  > span {
    font-size: 1.2rem;
    color: #838485;
  }
  > .bar {
    height: 0.1rem;
    background: #edeff1;
    border-radius: 1rem;
    width: 100%;
    margin: 1rem 0;
  }
`;

const RecentPost = styled.a`
  text-decoration: none;
  > .post-title {
    color: #222;
    font-size: 1.2rem;
  }

  > .stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #454748;
    font-size: 1.3rem;
    gap: 0.5rem;
    margin-top: 1rem;
    > .dot {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      background: #8a8d8e;
    }

    > .stats-icon {
      background: #f6f7f8;
      padding: 0.2rem 1rem;
      border-radius: 1rem;
      font-size: 1.1rem;
    }
  }
`;

export default RecentPosts;
