import React, { useState } from "react";
import RecentPosts from "../Components/RecentPosts";
import "../Styles/Membership.css";

import CheckoutImg from "../Images/2checkout.png";
import AmazonImg from "../Images/amazon-pay.png";
import AuthorizeImg from "../Images/authorize-net.png";
import PayoneerImg from "../Images/payoneer.png";
import PaypalImg from "../Images/paypal.png";
import SkrillImg from "../Images/skrill.png";
import StripeImg from "../Images/stripe.png";
import TransferwiseImg from "../Images/transferwise.png";
import WorldpayImg from "../Images/worldpay.png";
import SquareImg from "../Images/square.png";

function Membership() {
  // Tab state
  const [currentTabContent, setCurrentTabContent] = useState("tab1");
  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="about mt-5">
              <div className="base-card">
                <h3>
                  Welcome To
                  <a className="logo" href="index.html">
                    <span>D</span>eteriora
                  </a>
                </h3>
                <p>
                  Thanks for registering! We're thrilled to welcome you to our
                  growing community.
                </p>
                <div className="genre genre-4 mt-3">
                  <h3 style={{ fontSize: "11.7px", margin: "2px 0 0 0" }}>Activating Fee: $9</h3>
                </div>
                <p>
                  Allowing you full participation in all that Deteriora has to
                  offer. It's important to note, our membership rates will
                  gradually increase with time and growth. However, by joining
                  now, you secure this excellent rate for good.
                </p>
                <p>
                  Your decision today isn't just about immediate access, it's a
                  smart investment in a promising future at a fixed, unbeatable
                  price. We appreciate your trust in us and look forward to your
                  active participation.
                </p>
              </div>

              <div className="tabs">
                <button
                  className={`tab-button ${currentTabContent == "tab1" ? "active" : ""
                    }`}
                  onClick={() => setCurrentTabContent("tab1")}
                >
                  Services
                </button>
                <button
                  className={`tab-button ${currentTabContent == "tab2" ? "active" : ""
                    }`}
                  onClick={() => setCurrentTabContent("tab2")}
                >
                  Bank Account
                </button>
                <button
                  className={`tab-button ${currentTabContent == "tab3" ? "active" : ""
                    } `}
                  onClick={() => setCurrentTabContent("tab3")}
                >
                  Credit Card
                </button>
              </div>

              <div
                id="tab1"
                className={`tab-content ${currentTabContent == "tab1" ? "active" : ""
                  } base-card`}
              >
                <h3>Pay Via</h3>
                <div className="payment-modes">
                  <a href="" className="payment-mode">
                    <img src={CheckoutImg}></img>
                  </a>
                  <a href="" className="payment-mode scale-2">
                    <img src={AmazonImg}></img>
                  </a>
                  <a href="" className="payment-mode scale-2">
                    <img src={AuthorizeImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode scale-2">
                    <img src={PayoneerImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode scale-2">
                    <img src={PaypalImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode">
                    <img src={SkrillImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode">
                    <img src={SquareImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode">
                    <img src={StripeImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode">
                    <img src={TransferwiseImg} alt=""></img>
                  </a>
                  <a href="" className="payment-mode">
                    <img src={WorldpayImg} alt=""></img>
                  </a>
                </div>
              </div>

              <div
                id="tab2"
                className={`tab-content ${currentTabContent == "tab2" ? "active" : ""
                  } base-card`}
              >
                <h3>Add Account</h3>
                <form>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-bank"></i>
                    </label>
                    <input type="text" placeholder="Bank Name"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-location"></i>
                    </label>
                    <input type="text" placeholder="City / Town"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-map"></i>
                    </label>
                    <input type="text" placeholder="Branch Name"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-money-bill"></i>
                    </label>
                    <input type="text" placeholder="NEFT IFSC Code"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-money-check"></i>
                    </label>
                    <input type="number" placeholder="Account Number"></input>
                    <div className="border"></div>
                  </div>
                  <button className="btn btn-1">Add Your Bank</button>
                </form>
              </div>

              <div
                id="tab3"
                className={`tab-content ${currentTabContent == "tab3" ? "active" : ""
                  } base-card`}
              >
                <h3>Add Card</h3>
                <form>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-credit-card"></i>
                    </label>
                    <input
                      type="text"
                      placeholder="Debit or Credit Card Number"
                    ></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control select-control flex-column align-items-start">
                    <label>Card Type</label>
                    <select style={{ width: "100%" }}>
                      <option value="">Visa</option>
                      <option value="">Mastercard</option>
                    </select>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-calendar"></i>
                    </label>
                    <input type="text" placeholder="Expiration Date"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-key"></i>
                    </label>
                    <input type="password" placeholder="Security Code"></input>
                    <div className="border"></div>
                  </div>
                  <div className="f-control">
                    <label>
                      <i className="fal fa-location-arrow"></i>
                    </label>
                    <textarea placeholder="Billing Address"></textarea>
                    <div className="border"></div>
                  </div>
                  <button className="btn btn-1">Add Your Card</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Welcome To Deteriora</h4>
                <span>share links and engage in discussions</span>
                <a className="btn btn-1" href="index.html">
                  Explore Deteriora
                </a>
              </div>
            </div>
            <RecentPosts />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Membership;
