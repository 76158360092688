import { React, useEffect, useRef } from 'react'
import "../../Styles/Modal.css"

function Inbox({ inbox, showInbox }) {
    const inboxRef = useRef();
    const closeRef = useRef();

    // If the inbox modal is open and user clicks outside of it then close the menu
    useEffect(() => {
        const clickOutside = e => {
            if (inbox && inboxRef.current && !inboxRef.current.contains(e.target) && !closeRef.current.contains(e.target)) {
                showInbox(false)
            }
        }
        document.addEventListener("mousedown", clickOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", clickOutside)
        }
    }, [inbox]);
    return (
        <div id="inboxModal" className="modal">
            <div className="modal-content" ref={inboxRef} style={{ width: "50rem" }}>
                <span className="close" ref={closeRef} onClick={() => showInbox(false)}><i className="fal fa-times"></i></span>
                <h4>Your Inbox</h4>
                <div className="inbox-modal">
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                        <div className="post-body">
                            Hey! How you doing bud? Long Time No See!
                            <span className="delete"><i className="fal fa-trash"></i></span>
                        </div>

                    </div>
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="comment">
                                commented on you post.
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                        <div className="post-body">
                            Hey! How you doing bud? Long Time No See!
                            <span className="delete"><i className="fal fa-trash"></i></span>
                        </div>

                    </div>
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="comment">
                                commented on you post.
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                        <div className="post-body">
                            Hey! How you doing bud? Long Time No See!
                            <span className="delete"><i className="fal fa-trash"></i></span>
                        </div>

                    </div>
                    <div className="bar"></div>
                    <div className="post">
                        <div className="post-header">
                            <div className="profile-img">
                                <img src="https://styles.redditmedia.com/t5_2u624/styles/communityIcon_7vek3q3m0cn71.png" alt=""></img>
                            </div>
                            <div className="profile-name">
                                herokiller
                            </div>
                            <div className="comment">
                                commented on you post.
                            </div>
                            <div className="stats">
                                10:30 AM
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox