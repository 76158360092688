import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecentPosts from "../Components/RecentPosts";
import api from "../Components/apiService";
import "../Styles/About.css";

function About() {
  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="about mt-5">
            <div className="base-card">
                <h3>
                    Privacy Policy for 
                    <a className="logo" href="index.html">
                        Deteriora
                    </a>
                </h3>
                <p>
                    At Deteriora, we prioritize your privacy. This Privacy Policy outlines the types of personal information we collect and how we use, maintain, and disclose that information.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>1. Information Collection</h3>
                <p>
                    When you register on Deteriora, we may collect personal details such as your name, email address, and any other information you choose to provide. We might also track and save user interactions with our website for analytical purposes.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>2. Use of Collected Information</h3>
                <p>
                    We use the collected information for various purposes: to provide and improve our services, to notify you about changes, and to monitor the usage of our platform.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>3. Data Security</h3>
                <p>
                    Your data's security is crucial to us. We employ commercially acceptable means to protect your personal information, but remember that no online transmission method or electronic storage is 100% secure.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>4. Disclosure of Data</h3>
                <p>
                    We may disclose your data if required by law or in the belief that such action is necessary to comply with legal obligations.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>5. Third-Party Services</h3>
                <p>
                    Our platform may contain links to third-party websites. If you click on a third-party link, you will be directed to their site. We have no control over these sites and assume no responsibility for the content or practices of third-party sites.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>6. Cookies</h3>
                <p>
                    We use cookies to enhance your experience on our platform. Cookies are files with small amounts of data that may include an anonymous unique identifier. You can instruct your browser to refuse cookies or notify you when a cookie is being sent.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>7. Changes to This Privacy Policy</h3>
                <p>
                    We may update our Privacy Policy from time to time. You're advised to review this page periodically for changes. Your continued use of our platform signifies your acceptance of the changes.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Contact Us</h3>
                <p>
                    If you have any questions about this Privacy Policy, please contact [your contact details here].
                </p>
            </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Welcome To Deteriora</h4>
                <span>share links and engage in discussions</span>
                <Link className="btn btn-1" to="/">
                  Explore Deteriora
                </Link>
              </div>
            </div>
            {/* <RecentPosts /> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
