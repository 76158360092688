import React, { useState, useEffect } from "react";
import BannerImg from "../Images/profile_icon.png";
import ProfileBanner from "../Images/banner.png";
import profileImg from "../Images/profile_icon.png";
import api from "../Components/apiService";
import { useParams } from "react-router-dom";

function ResetPassword() {
  const { token } = useParams(); 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await api.post("user/reset", { token });
        if (response && response.success) {  
          setIsValidToken(true);
        } else {
          setMessage("Invalid or expired token");
        }
      } catch (error) {
        setMessage("Error checking token validity");
      }
    };
    checkTokenValidity();
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password || !confirmPassword) {
      setMessage('Please fill out both fields.');
      return;
    }
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      const response = await api.post("user/passwordReset", {
        token,
        password
      });
      if (response.success) {
        setMessage("Password successfully updated. Please login.");
        setIsSuccess(true);
      } else if (response.message) {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Failed to process request");
    }
  };

  return (
    <>
      <div className="container-fluid banner-profile p-0">
        <div className="profile-banner">
          <img src={ProfileBanner} alt="Banner"></img>
        </div>
        <div className="container-fluid profile-container">
          <div className="container-xs">
            <div className="profile-info">
              <div className="profile-img">
                <img src={profileImg} alt="Profile"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="container-fluid mt-3">
        <div className="container-xs">
          <div className="row">
            <div className="col-md-8 pt-4">
              {isValidToken && !isSuccess ? (
                <div className="base-card mt-3">
                  <h3>Reset Password</h3>
                  <form className="edit-profile-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="f-control">
                          <label><i className="fal fa-lock"></i></label>
                          <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="f-control">
                          <label><i className="fal fa-lock"></i></label>
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <button className="btn btn-1" onClick={handleSubmit}>Reset Password</button>
                      </div>
                      {message && (
                        <div className="col-md-12 mt-3">
                          {message}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="base-card mt-3">
                  <h3>{isSuccess ? 'Success' : 'Token Invalid'}</h3>
                  <p>{message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ResetPassword;