// apiService.js
import { authService } from "./authService";

let BASE_URL;

if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://deteriora.com/api/1.0";
} else {
  BASE_URL = "http://localhost:5777/api/1.0";
}

async function post(endpoint, data) {
  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  const token = authService.getAccessToken();
  if (token) {
    headers["x-user-session-token"] = token;
  }

  const response = await fetch(`${BASE_URL}/${endpoint}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });

  return await response.json();
}

async function get(endpoint, params = {}) {
  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  const token = authService.getAccessToken();
  if (token) {
    headers["x-user-session-token"] = token;
  }

  const queryString = new URLSearchParams(params).toString();

  const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
    method: "GET",
    headers: headers,
  });

  return await response.json();
}

export default { post, get };
