import React, { useState } from 'react';
import api from '../Components/apiService';  // Assuming your API service is structured for POST requests.
import RecentPosts from '../Components/RecentPosts';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState(null);
    const [apiError, setApiError] = useState(null);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let errorMessages = {};
        if (!formData.name) errorMessages.name = "Name is required";
        if (!formData.email) errorMessages.email = "Email is required";
        if (!formData.message) errorMessages.message = "Message is required";
        
        if (Object.keys(errorMessages).length) {
            setErrors(errorMessages);
            return;
        }

        try {
            const response = await api.post('user/contact', formData);
            if (response && response.success) {
                setSuccessMessage("Your message was sent. Thank you for contacting us!");
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
                setErrors({});
            } else {
                setApiError("There was an issue sending your message. Please try again later.");
            }
        } catch (error) {
            setApiError("There was an error sending your message. Please try again later.");
        }
    };

    return (
        <main className="container-fluid">
            <div className="container-xs">
                <div className="row">
                    <div className="col-md-8">
                        <div className="contact mt-5">
                            <div className="base-card">
                                <h3>Contact Us For More Queries</h3>
                            </div>
                            <div className="base-card mt-3">
                                {successMessage ? (
                                    <div className="success-message">
                                        {successMessage}
                                    </div>
                                ) : (
                                    <div className="signup-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className={`f-control ${errors.name ? 'error' : ''}`}>
                                                <label><i className="fal fa-user"></i></label>
                                                <input 
                                                    type="text" 
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <p className="error-text">{errors.name}</p>}
                                                <div className="border"></div>
                                            </div>
                                            <div className={`f-control ${errors.email ? 'error' : ''}`}>
                                                <label><i className="fal fa-envelope"></i></label>
                                                <input 
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <p className="error-text">{errors.email}</p>}
                                                <div className="border"></div>
                                            </div>
                                            <div className="f-control">
                                                <label><i className="fal fa-phone"></i></label>
                                                <input 
                                                    type="text" 
                                                    placeholder="Phone Number"
                                                    name="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleChange}
                                                />
                                                <div className="border"></div>
                                            </div>
                                            <div className="f-control">
                                                <label><i className="fal fa-book"></i></label>
                                                <input 
                                                    type="text"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                />
                                                <div className="border"></div>
                                            </div>
                                            <div className={`f-control ${errors.message ? 'error' : ''}`}>
                                                <label><i className="fal fa-message"></i></label>
                                                <textarea
                                                    placeholder="Message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.message && <p className="error-text">{errors.message}</p>}
                                                <div className="border"></div>
                                            </div>
                                            <div className="signup-footer">
                                                <button type="submit" className="btn btn-1 mt-2 me-auto">Submit</button>
                                                {apiError && <p className="error-text">{apiError}</p>}
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="base-card membership mt-5">
                            <div className="membership-icon">
                                <i className="far fa-bullhorn"></i>
                            </div>
                            <div className="membership-content">
                                <h4>Welcome To Deteriora</h4>
                                <span>share links and engage in discussions</span>
                                <a className="btn btn-1" href="index.html">Explore Deteriora</a>
                            </div>
                        </div>
                        {/* <RecentPosts /> */}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Contact;