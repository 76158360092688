// authService.js

const TOKEN_COOKIE = 'accessToken';
const REFRESH_TOKEN_COOKIE = 'refreshToken';

export const authService = {
    setTokens: function(accessToken, refreshToken) {
        document.cookie = `${TOKEN_COOKIE}=${accessToken}; path=/;`;
        document.cookie = `${REFRESH_TOKEN_COOKIE}=${refreshToken}; path=/;`;
    },
    getAccessToken: function() {
        return this.getCookie(TOKEN_COOKIE);
    },
    getRefreshToken: function() {
        return this.getCookie(REFRESH_TOKEN_COOKIE);
    },
    logout: function() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('sessionInfo');
        document.cookie = `${TOKEN_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        document.cookie = `${REFRESH_TOKEN_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    },
    getCookie: function(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    // You might also want to add a refreshToken function here in the future.
};