import { useEffect, useRef, useState } from 'react';
import "../../Styles/Modal.css";
import { authService } from '../authService';
import api from '../apiService';

function SignUp({ signup, showSignUp, showLogin }) {
    const signupRef = useRef();
    const closeRef = useRef();
    
    const [validationErrors, setValidationErrors] = useState({
        username: false,
        invalidUsername: false,
        password: false,
    });

    const usernamePattern = /^[a-zA-Z0-9]+$/;

    useEffect(() => {
        const clickOutside = e => {
            if (signup && signupRef.current && !signupRef.current.contains(e.target) && !closeRef.current.contains(e.target)) {
                showSignUp(false);
            }
        };

        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, [signup]);

    const handleModalSwitch = () => {
        showSignUp(false);
        showLogin(true);
    }

    const handleUsernameChange = (e) => {
        const username = e.target.value;
        const invalidUsername = !usernamePattern.test(username) && username !== "";  // Checks for valid username or if input is empty
        
        // Check if username is non-empty
        const isUsernameEmpty = username === "";
    
        setValidationErrors(prevState => ({ ...prevState, invalidUsername, username: isUsernameEmpty }));
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const [usernameInput, passwordInput] = e.target.elements;
        const username = usernameInput.value;
        const password = passwordInput.value;

        const usernamePattern = /^[a-zA-Z0-9]+$/;  // Regular expression: Matches only letters and numbers

        const newValidationErrors = {
            username: !username,
            invalidUsername: username && !usernamePattern.test(username),  // Check if username matches the pattern
            password: !password,
        };

        setValidationErrors(newValidationErrors);

        if (!newValidationErrors.username && !newValidationErrors.invalidUsername && !newValidationErrors.password) {
            const data = await api.post('user/create', { username, password });
            
            if (data['x-user-access-token'] && data['x-user-refresh-token']) {
                authService.setTokens(data['x-user-access-token'], data['x-user-refresh-token']);
                window.location.reload();
            } else if (data.message) {
                alert(data.message);
            } else {
                alert('An error occurred. Please try again later.');
            }
        }
    }

    return (
        <div id="signUpModal" className="modal">
            <div className="modal-content" ref={signupRef} style={{ width: "50rem" }}>
                <span className="close" ref={closeRef} onClick={() => showSignUp(false)}>
                    <i className="fal fa-times"></i>
                </span>
                <h4>Sign Up</h4>
                <div className="bar"></div>
                <div className="signup-form">
                    <form onSubmit={handleSubmit}>
                        <div className={`f-control ${validationErrors.username ? 'error' : ''} ${validationErrors.invalidUsername ? 'error' : ''}`}>
                            <label><i className="fal fa-user"></i></label>
                            <input type="text" placeholder="Username" onChange={handleUsernameChange} />
                            {validationErrors.username && <span className="error-text-box">Required</span>}
                            {validationErrors.invalidUsername && <span className="error-text-box">Invalid</span>}
                            <div className="border"></div>
                        </div>
                        <div className={`f-control ${validationErrors.password ? 'error' : ''}`}>
                            <label><i className="fal fa-key"></i></label>
                            <input type="password" placeholder="Password" />
                            {validationErrors.password && <span className="error-text-box">Required</span>}
                            <div className="border"></div>
                        </div>
                        <span>Membership is Free</span>
                        <div className="signup-footer">

                        
                            <p id="log-in" onClick={handleModalSwitch}>Already have an account?</p>
                        </div>
                        <div className="signup-footer">
                            <label htmlFor="agreeTerms">
                                By signing up, I accept the <a href="/terms">terms</a>.
                            </label>
                            <button type="submit" className="btn btn-1 mt-2">Sign Up</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignUp;