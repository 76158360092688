import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ProfileBanner from "../Images/banner.png";
import Report from "../Components/Modals/Report";
import api from "../Components/apiService";
import PostCard from "../Components/PostCard";
import ProfileComment from "../Components/ProfileComment";
import RecentPosts from "../Components/RecentPosts";
import "../Styles/Post.css";
import CheckoutImg from "../Images/profile_icon.png";

function Profile({showLogin, isLoggedIn}) {

  const { userID } = useParams();
  const [currentUser, setCurrentUser] = useState({});
  const [historyItems, setHistoryItems] = useState([]);
  const [reportModal, setReportModal] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`user/${userID}`);
        setCurrentUser(response);
        console.log(currentUser);
      } catch (error) {
        console.error("Fetching user failed:", error);
      }
    };

    const fetchHistory = async () => {
      try {
        const response = await api.get(`user/history/${userID}`);
        const items = response.data ? response.data : [];
        setHistoryItems(items);
      } catch (error) {
        console.error("Fetching user history failed:", error);
      }
    };

    fetchUser();
    fetchHistory();
  }, [userID]);

  const handleReportModal = () => {
    setReportModal(!reportModal);
  };

 

  return (
    <div>
      <div className="container-fluid banner-profile p-0">
        <div className="profile-banner">
          <img src={ProfileBanner} alt="Profile banner"></img>
        </div>
        <div className="container-fluid profile-container">
          <div className="container-xs">
            <div className="profile-info">
              <div className="profile-img">
                <img src={CheckoutImg} alt="Profile"></img>
              </div>
              <div className="profile-name">{currentUser && currentUser.name}</div>
              <button className="btn btn-1 ms-auto">
                <i className="fal fa-message me-2"></i>Message
              </button>
              {/* <button className="btn btn-2 report-icon" onClick={() => showReportModal(true)}>
                <i className="fal fa-flag me-2"></i> <span>Report</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <main className="container-fluid mt-3">
        <div className="container-xs">
          <div className="row">
            <div className="col-md-8 pt-4">
              <div className="base-card mt-3">
                <h3>History</h3>
                <form>
                  <div className="f-control select-control">
                    <label>Sort By</label>
                    <select>
                      <option value="recent">Newest</option>
                      <option value="upvotes">Upvotes</option>
                    </select>
                  </div>
                </form>
              </div>

              {historyItems && historyItems.map((item) => {
                if (item.categoryID) {
                  return (
                    <PostCard
                      key={item.postID}
                      postID={item.postID}
                      url={item.url}
                      score={item.score}
                      userID={item.userID}
                      name={item.user.username}
                      genre={item.category.name}
                      genre_class={item.category.link}
                      date={item.dateStamp}
                      title={item.title}
                      upvotes={item.upvotes}
                      comments={item.commentCount}
                      downvotes={item.downvotes}
                      upVoted={item.upVoted}
                      downVoted={item.downVoted}
                      ago={item.ago}
                      currentUser={currentUser.username}
                      isLoggedIn={isLoggedIn}
                    />
                  );
                } else if (item.commentID) {
                  return (
                    <ProfileComment
                      key={item.commentID}
                      commentID={item.commentID}
                      score={item.score}
                      userID={item.userID}
                      content={item.text}
                      upvotes={item.upvotes}
                      downvotes={item.downvotes}
                      postID={item.postID}
                      parentID={item.parentID}
                      date={item.dateStamp}
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                      user={item.user}
                      post={item.post}
                      upVoted={item.upVoted}
                      downVoted={item.downVoted}
                      ago={item.ago}
                      showLogin={showLogin}
                      currentUser={currentUser.username}
                      isLoggedIn={isLoggedIn}
                    />
                
                  );
                }
                return null;
              })}
            </div>
            
            <div className="col-md-4 pt-4">
              <div className="base-card post-profile mt-3">
                <div className="top-background">
                  <img src={ProfileBanner} alt="Profile Banner"></img>
                </div>
                <div className="post-profile-header">
                  <div className="profile-img">
                    <img src={CheckoutImg} alt="Profile"></img>
                  </div>
                  <div className="profile-name">{currentUser && currentUser.username}</div>
                </div>
                <div className="post-profile-about">
                  <p>{currentUser && currentUser.bio}</p>
                  <span>Joined On {currentUser && currentUser.displayDate}</span>
                </div>
                <div className="post-profile-stats">
                  <div className="icons">
                    <i className="fas fa-fire"></i>
                    Voting Power : {currentUser && currentUser.votingPower}
                  </div>
                  <div className="icons">
                    <i className="fas fa-user-plus"></i>
                    Invited Users : 0{currentUser && currentUser.invitedUsers}
                  </div>
                </div>
              </div>
              {/* <RecentPosts /> */}
            </div>
          </div>
        </div>
      </main>

      {/* {reportModal && <Report closeReportModal={handleReportModal} />} */}
    </div>
  );
}

export default Profile;
