import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecentPosts from "../Components/RecentPosts";
import api from "../Components/apiService";
import "../Styles/About.css";

function About() {
  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="about mt-5">
            <div className="base-card">
                <h3>
                    Terms and Conditions for 
                    <a className="logo" href="index.html">
                        Deteriora
                    </a>
                </h3>
                <p>
                    By accessing or using the Deteriora website, you agree to these terms. If not, please refrain from using our platform.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Changes and Modifications</h3>
                <p>
                    Deteriora retains the right to modify these Terms at any time. Your continued engagement with our platform means you accept these changes.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Membership and Fees</h3>
                <p>
                    Our platform is free until our user base reaches 10,000. Thereafter, account creation fees apply as per the detailed structure on our About page. These fees are non-refundable.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Voting Dynamics</h3>
                <p>
                    Voting power is adjusted based on userbase growth. For more information, refer to our About page.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Account Termination Policy</h3>
                <p>
                    If you invite someone and they violate our rules, both your and their accounts will be terminated. No exceptions, no refunds.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Responsibility of Invitations</h3>
                <p>
                    Your account status is tied to both your actions and those of your invitees. This responsibility extends through the entire chain of invitations.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Conduct Rules</h3>
                <p>
                    Strict rules are in place to ensure quality content. Breaching these rules may lead to immediate account termination.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Limitations and Indemnity</h3>
                <p>
                    Deteriora offers its platform without warranties or guarantees. By using our site, you agree to defend and hold Deteriora harmless against all claims or liabilities.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Governing Law and Dispute Resolution</h3>
                <p>
                    All terms are under the jurisdiction of [your country/state]. Any disputes will be resolved through binding arbitration.
                </p>
            </div>

            <div className="base-card mt-3">
                <h3>Contact Us</h3>
                <p>
                    For questions or clarifications, please reach out to us.
                </p>
            </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Welcome To Deteriora</h4>
                <span>share links and engage in discussions</span>
                <Link className="btn btn-1" to="/">
                  Explore Deteriora
                </Link>
              </div>
            </div>
            {/* <RecentPosts /> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
