import { React, useEffect, useRef, useState } from "react";
import "../../Styles/Modal.css";
import api from '../apiService';
import { useNavigate } from 'react-router-dom';

function TextPost({ textPost, showTextPost, showUrlPost }) {
  const textPostRef = useRef();
  const closeRef = useRef();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(null);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState(null);
  const [categoryID, setCategoryID] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryError, setCategoryError] = useState(null);


  const validateTitle = (title) => {
    const regex = /^[a-zA-Z0-9$,.!-"'\s]+$/;
    return regex.test(title);
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (!validateTitle(value)) {
      setTitleError("Invalid title");
    } else {
      setTitleError(null);
    }
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    if (value.length > 5000) {
      setTextError("Text exceeds 5000 characters");
    } else {
      setText(value);
      setTextError(null);
    }
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (
        textPost &&
        textPostRef.current &&
        !textPostRef.current.contains(e.target) &&
        !closeRef.current.contains(e.target)
      ) {
        showTextPost(false);
      }
    };
    document.addEventListener("mousedown", clickOutside);
    getCategories();
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [textPost]);

  const getCategories = async () => {
    try {
      const data = await api.get("post/categories");
      setCategories(data);
    } catch (error) {
      console.error("Fetching categories failed:", error);
    }
  };

  const handleModalSwitch = () => {
    showTextPost(false);
    showUrlPost(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!title) {
      setTitleError("Title is required");
      hasErrors = true;
    } else if (!validateTitle(title)) {
      setTitleError("Invalid title");
      hasErrors = true;
    }

    // Category validation
    if (!categoryID || categoryID === '-') {
      setCategoryError("Required");
      hasErrors = true;
    } else {
      setCategoryError(null);
    }

    if (text.length > 5000) {
      setTextError("Text exceeds 5000 characters");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const postDetails = {
      title: title,
      url: "", // This is a text post, so URL is empty.
      text: text,
      categoryID: categoryID
    };

    try {
      const response = await api.post('post/create', postDetails);
      if (response && response.postID) {
        const urlTitle = title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-');
        showTextPost(false);
        navigate(`/r/${categoryID}/${response.postID}/${urlTitle}`);
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <div id="textPostModal" className="modal">
      <div className="modal-content" ref={textPostRef} style={{ width: "50rem" }}>
        <span className="close" ref={closeRef} onClick={() => showTextPost(false)}>
          <i className="fal fa-times"></i>
        </span>
        <h4>Create A Text Post</h4>
        <div className="bar"></div>
        <div className="signup-form">
          <span className="d-inline-block membership-color">
            You are submitting a text-based post. Speak your mind. A title is
            required, but expanding further in the text field is not. Beginning
            your title with "vote up if" is violation of intergalactic law.<br />
            <b style={{color:'#A50'}}>No Political Posts.</b>
          </span>
          <form onSubmit={handleSubmit}>
            <div className={`f-control ${titleError ? 'error' : ''}`}>
              <label>
                <i className="fal fa-crown"></i>
              </label>
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={handleTitleChange}
                className={titleError ? 'error-border' : ''}
              />
              {titleError && <p className="error-text">{titleError}</p>}
              <div className="border"></div>
            </div>
            <div className={`f-control ${textError ? 'error' : ''}`}>
              <label>
                <i className="fal fa-text"></i>
              </label>
              <textarea 
                placeholder="Text" 
                value={text} 
                onChange={handleTextChange} 
                className={textError ? 'error-border' : ''}
              ></textarea>
              {textError && <p className="error-text">{textError}</p>}
              <div className="border"></div>
            </div>
            <div className="f-control select-control">
              <label>Category:</label>
              <select value={categoryID} onChange={e => setCategoryID(e.target.value)}>
                  <option value="-">-</option>
                  {categories.map(cat => <option key={cat.categoryID} value={cat.categoryID}>{cat.name}</option>)}
              </select>
              {categoryError && <p className="error-text">{categoryError}</p>}
            </div>
            <div className="signup-footer">
              <p id="urlPostPBtn" onClick={handleModalSwitch}>
                URL Post?
              </p>
              <button type="submit" className="btn btn-1 mt-2">
                Create Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TextPost;