import React, { useState } from "react";
import ProfileBanner from "../Images/banner.png";
import profileImg from "../Images/profile_icon.png";
import api from "../Components/apiService";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post("user/forgot", {
                email: email,
            });

            if (response.success === 1) {
                setFormSubmitted(true);
                setMessage("If the email is registered, a reset link will be sent shortly.");
            } else if (response.message) {
                setMessage(response.message);
            }
        } catch (error) {
            setMessage("Failed to process request");
        }
    };

    return (
        <>
            <div className="container-fluid banner-profile p-0">
                <div className="profile-banner">
                    <img src={ProfileBanner} alt="Banner"></img>
                </div>
                <div className="container-fluid profile-container">
                    <div className="container-xs">
                        <div className="profile-info">
                            <div className="profile-img">
                                <img src={profileImg} alt="Profile"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main className="container-fluid mt-3">
                <div className="container-xs">
                    <div className="row">
                        <div className="col-md-8 pt-4">
                            <div className="base-card mt-3">
                                <h3>Forgot Password</h3>
                                {formSubmitted ? (
                                    <div className="mt-3">
                                        <p>{message}</p>
                                    </div>
                                ) : (
                                    <form className="edit-profile-form">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="f-control">
                                                    <label>
                                                        <i className="fal fa-envelope"></i>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="col-md-4 my-auto">
                                                <button className="btn btn-1" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </div>
                                        {message && (
                                            <div className="row">
                                                <div className="col-md-12 mt-3">
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ForgotPassword;
