import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate
import Report from "./Modals/Report";
import Voting from "./voting";
import api from "./apiService";
import EditIcon from "../Images/edit.png";
import DeleteIcon from "../Images/delete.png";

function PostCard({
  postID,
  url,
  username,
  userID,
  name,
  score,
  genre,
  genre_class,
  date,
  title,
  upvotes,
  comments,
  downvotes,
  ago,
  showLogin,
  currentUser,
  isLoggedIn,
  downVoted,
  upVoted
}) {
  const navigate = useNavigate();
  
  const [confirmDelete, setConfirmDelete] = useState(false); // State for delete confirmation

  function decodeHtmlEntities(text) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  const handleCommentIconClick = () => {
    navigate(`/r/${genre_class}/` + postID + `/` + slug);
  };

  const [reportModal, showReportModal] = useState(false);
  const slug = title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  const handleDelete = async () => {
    try {
      const response = await api.post("post/delete", {
        postID: postID,
      });

      if (response && response.success !== undefined) {
        // Here you can handle what to do after deleting, e.g., navigating to another page or removing the post visually
        navigate('/'); // as an example, it will redirect to the homepage
      } else {
        console.error("Error deleting post:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <>
      {reportModal ? (
        <Report
          reportModal={reportModal}
          id={postID}
          showReportModal={showReportModal}
        />
      ) : (
        <></>
      )}
      <PostContainer className="base-card feed-post mt-3">
        <PostHeader className="post-header">
          <div className="profile-name">
            <Link to={`/u/${userID}/${username}`} className="profile-name">
              {username}
            </Link>
          </div>
          <div className="stats ms-2 ">
            <Link to={`/r/${genre_class}`}>
              <div className={`genre ${genre_class}`}>{genre}</div>
            </Link>
            <span className="ms-auto">{ago}</span>
          </div>
        </PostHeader>
        <PostBody className="post-body">
          <Link to={url}>
            <h4>{decodeHtmlEntities(title)}</h4>
          </Link>
        </PostBody>
        <PostFooter className="post-footer" postid={postID}>
          <Voting
            initialUpvotes={upvotes}
            initialDownvotes={downvotes}
            type="postID"
            id={postID}
            showLogin={showLogin}
            upVoted = {upVoted}
            downVoted = {downVoted}
          />

          <div className="icons comment-link" onClick={handleCommentIconClick}>
            <i className="fal fa-comment-alt"></i> {comments}
          </div>

          {/*Check if logged in and current user is owner of post*/}

          {isLoggedIn && currentUser === name && (
            <>
              {!confirmDelete ? (
                <div className="icons ms-auto delete-icon" onClick={() => setConfirmDelete(true)}>
                  <img src={DeleteIcon} alt="Delete" />
                </div>
              ) : (
                <ConfirmDeleteContainer className="confirm-delete ms-auto">
                  <span>Delete?</span>
                  <button onClick={handleDelete}>Yes</button>
                  <button onClick={() => setConfirmDelete(false)}>No</button>
                </ConfirmDeleteContainer>
              )}
            </>
          )}
          {/* <div
            className="icons ms-auto report-icon"
            onClick={() => showReportModal(true)}
          >
            <i className="fal fa-flag"></i>
            <span>Report</span>
          </div> */}
        </PostFooter>
      </PostContainer>
    </>
  );
}

{
  /* Main CSS for an Indivual Post */
}

const PostContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: 0.1rem solid #ccc;
  border-radius: 0.5rem;

  > ol {
    list-style-position: inside;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
`;

const PostHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  > .profile-img {
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem;
    margin-right: 0.5rem;
  }
  > .profile-img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  > .profile-name {
    font-weight: bold;
  }
  > .stats {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #454748;
  }
`;

const PostBody = styled.div`
  font-size: 1.3rem;
  color: #454748;

  > a {
    color: #136ac8;
    font-size: 1.2rem;
    text-decoration: none;
    > h4 {
      font-size: 1.5rem;
      margin: 1.2rem 0;
      margin-bottom: 0.55rem;
      color: #222;
      font-weight: bold;
    }
  }
`;

const PostFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  font-size: 1.2rem;
  margin-top: 2rem;
  > .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.2rem 0.8rem;
    border-radius: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
    &:hover {
      color: #5865f2;
    }
    > i {
      font-size: 2rem;
    }
  }
`;


const ConfirmDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  span {
    font-weight: 600;
  }

  button {
    padding: 0.3rem 0.8rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    margin: 0 0.2rem;

    &:first-child {
      background-color: #e74c3c;
      color: white;

      &:hover {
        background-color: #d43c2c;
      }
    }

    &:last-child {
      background-color: #5865f2;
      color: white;

      &:hover {
        background-color: #4b56d0;
      }
    }
  }
`;
export default PostCard;
