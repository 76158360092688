import NavBar from "./Components/NavBar";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import "./App.css";

import Home from "./Pages/Home";
import About from "./Pages/About";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Profile from "./Pages/Profile";
import EditProfile from "./Pages/EditProfile";
import Post from "./Pages/Post";
import Reset from "./Pages/Reset";
import Forgot from "./Pages/Forgot";
import Category from "./Pages/Category";
import Inbox from "./Components/Modals/Inbox";
import SignUp from "./Components/Modals/SignUp";
import Alert from "./Components/Modals/Alert";
import Confirm from "./Pages/Confirm";
import Welcome from "./Pages/Welcome";
import Login from "./Components/Modals/Login";
import TextPost from "./Components/Modals/TextPost";
import UrlPost from "./Components/Modals/UrlPost";
import Membership from "./Pages/Membership";
import Contact from "./Pages/Contact";
import "./Components/voting";
import Voting from "./Components/voting";

function App() {
  // States for all the modals
  const [inbox, showInbox] = useState(false);
  const [signup, showSignUp] = useState(false);
  const [login, showLogin] = useState(false);
  const [textPost, showTextPost] = useState(false);
  const [urlPost, showUrlPost] = useState(false);
  const [alert, showAlert] = useState(false);

  // Current logged in user state
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div className="App">
      {/* Pass Down the above states to Navbar for the click functions */}
      <NavBar
        showInbox={showInbox}
        showSignUp={showSignUp}
        showLogin={showLogin}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        showTextPost={showTextPost}
        showUrlPost={showUrlPost}
      />

      {/* Routes defined for all the pages */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
              showTextPost={showTextPost}
              showUrlPost={showUrlPost}
              isLoggedIn={isLoggedIn}
              showLogin={showLogin}
            />
          }
        />
        <Route
          path="/r/:category"
          element={
            <Category showTextPost={showTextPost} showUrlPost={showUrlPost} />
          }
        /> 
        <Route
          path="/welcome"
          element={
            <Welcome />
          }
        />
        <Route
          path="/r/:category/:postID/*"
          element={<Post 
            isLoggedIn={isLoggedIn} 
            showLogin={showLogin} />}
        />
        <Route
          path="/u/:userID/:username"
          element={<Profile showLogin={showLogin} isLoggedIn={isLoggedIn}/>}
        />
        <Route path="/EditProfile" element={<EditProfile />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route  path="/confirm/:token" element={<Confirm />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reset/:token" element={<Reset />} />
      </Routes>

      {/* All the Modals */}
      {inbox && <Inbox inbox={inbox} showInbox={showInbox} />}
      {signup && (
        <SignUp signup={signup} showSignUp={showSignUp} showLogin={showLogin} />
      )}
      {login && (
        <Login login={login} showLogin={showLogin} showSignUp={showSignUp} />
      )}
      {textPost && (
        <TextPost
          textPost={textPost}
          showTextPost={showTextPost}
          showUrlPost={showUrlPost}
        />
      )}
      {urlPost && (
        <UrlPost
          urlPost={urlPost}
          showTextPost={showTextPost}
          showUrlPost={showUrlPost}
        />
      )}
      {alert && <Alert alert={alert} showAlert={showAlert} />}
    </div>
  );
}

export default App;
