import React, { useState } from "react";
import styled from "styled-components";
import Voting from "./voting";
import EditIcon from "../Images/edit.png";
import DeleteIcon from "../Images/delete.png";
import api from "./apiService"; 

function ProfileComment({
  commentID,
  content,
  score,
  upvotes,
  downvotes,
  date,
  user,
  post,
  upVoted,
  downVoted,
  ago,
  showLogin,
  currentUser,
  isLoggedIn,
}) {
  const [editMode, setEditMode] = useState(false);
  const [commentText, setCommentText] = useState(content);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleEdit = () => {
    setEditMode(true);
  };

  function decodeHtmlEntities(text) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }
  
  const handleCancel = () => {
    setEditMode(false);
    setCommentText(content);  // Reset to original content
  };

  const handleUpdate = async () => {
    try {
      const response = await api.post("comment/update", {
        commentID: commentID,
        text: commentText,
      });

      if (response && response.success !== undefined) {
        setEditMode(false); // Hide the textarea
        setCommentText(commentText);
      } else {
        console.error("Error updating comment");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await api.post("comment/delete", {
        commentID: commentID,
      });

      if (response && response.success !== undefined) {
        const element = document.getElementById("comment" + commentID);
        
        if (element) {
            element.remove();
        }
        
      } else {
        console.error("Error deleting comment:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <ProfileCommentContainer id={"comment"+commentID} className="base-card feed-post profile-comment mt-3">
      <div className="post-header">
        <div className="profile-name">{user.username}</div>
        <div className="stats ms-2">
          <span className="ms-auto">{ago}</span>
        </div>
      </div>
      <div className="post-body">
        {editMode ? (
          <EditContainer>
            <textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} />
            <div className="btn-container">
              <button onClick={handleUpdate}>Update</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </EditContainer>
        ) : (
          <a
            style={{ textDecoration: "none" }}
            href={`/r/${post.category.link}/${post.postID}/${post.title}`}
          >
            <p id="commentText">{decodeHtmlEntities(commentText)}</p>
          </a>
        )}
      </div>
      <CommentFooter commentid={commentID}>
        <Voting
          initialUpvotes={upvotes}
          initialDownvotes={downvotes}
          type="commentID"
          id={commentID}
          showLogin={showLogin}
          upVoted = {upVoted}
          downVoted = {downVoted}
        />
        <IconContainer className="icons report-icon">
          <i className="fal fa-flag"></i>
          <span>Report</span>
        </IconContainer>
        {isLoggedIn && currentUser === user.username && (
          <>
            {!editMode && !confirmDelete && (
              <div className="icons ms-auto edit-icon" onClick={handleEdit}>
                <img src={EditIcon} alt="Edit" />
              </div>
            )}
            {!confirmDelete ? (
              <div className="icons delete-icon" onClick={() => setConfirmDelete(true)}>
                <img src={DeleteIcon} alt="Delete" />
              </div>
            ) : (
              <ConfirmDeleteContainer className="confirm-delete ms-auto">
                <span>Delete?</span>
                <button onClick={handleDelete}>Yes</button>
                <button onClick={() => setConfirmDelete(false)}>No</button>
              </ConfirmDeleteContainer>
            )}
          </>
        )}
      </CommentFooter>
    </ProfileCommentContainer>
  );
}

const ProfileCommentContainer = styled.div`
  // existing styles here
`;

const CommentFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  font-size: 1.2rem;
  padding: 1rem;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  .btn-container {
    display: flex;
    gap: 0.5rem;

    button {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: #5865f2;
      color: white;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #4b56d0;
      }

      &:last-child {
        background-color: #e74c3c;

        &:hover {
          background-color: #d43c2c;
        }
      }
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
  background: #f6f7f8;
  border: 0.1rem solid #edeff1;
  cursor: pointer;

  &:hover {
    color: #5865f2;
  }

  & > i {
    font-size: 2rem;
  }
`;

const ConfirmDeleteContainer = styled.div`

  display: flex;
  justify-content: flex-end;  // pushes content to the right
  align-items: center;
  gap: 1rem;

  span {
    font-weight: 600; // makes the text slightly bold
  }

  button {
    padding: 0.3rem 0.8rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    margin: 0 0.2rem;

    &:first-child {
      background-color: #e74c3c;
      color: white;

      &:hover {
        background-color: #d43c2c;
      }
    }

    &:last-child {
      background-color: #5865f2;
      color: white;

      &:hover {
        background-color: #4b56d0;
      }
    }
  }
`;

export default ProfileComment;
