import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PostCard from '../Components/PostCard';
import api from '../Components/apiService';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

function LandingPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [topPosts, setTopPosts] = useState([]);

  useEffect(() => {
    const fetchTopPosts = async () => {
      try {
        const response = await api.get('post/top'); // Confirm this endpoint
        if (response) {
          setTopPosts(response); // Ensure this sets the correct data structure
        }
      } catch (error) {
        console.error("Failed to fetch top posts:", error);
      }
    };

    fetchTopPosts();
  }, []);

  return (
    <MainContainer>
      <h1>Welcome to Deteriora</h1>
      <p>
        Discover a new dimension of sharing, discussing, and experiencing content.
      </p>

      {isLoggedIn ? (
        <p>Welcome back! Dive straight into your favorite discussions.</p>
      ) : (
        <>
          <button onClick={() => setShowSignUp(true)}>Sign Up</button>
          <p>
            Hurry and grab your free account. Don't miss out on full voting power!
          </p>
        </>
      )}

      {showSignUp && (
        <div>
          {/* Insert your SignUp modal code here */}
        </div>
      )}

      {showLogin && (
        <div>
          {/* Insert your Login modal code here */}
        </div>
      )}

      <h2>Top Posts</h2>
      {topPosts.length === 0 ? (
        <p>Loading top posts...</p>
      ) : (
        topPosts.map((post) => (
          <PostCard
            key={post.postID}
            postID={post.postID}
            url={post.url}
            username={post.user.username}
            userID={post.user.userID}
            score={post.score}
            genre={post.category.name}
            genre_class={post.category.link}
            date={post.dateStamp}
            title={post.title}
            upvotes={post.upvotes}
            comments={post.commentCount}
            downvotes={post.downvotes}
            upVoted={post.upVoted}
            downVoted={post.downVoted}
            ago={post.ago}
          />
        ))
      )}

      <p>
        Join our rapidly growing community and be part of the next big thing!
      </p>
    </MainContainer>
  );
}

export default LandingPage;
