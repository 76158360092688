import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecentPosts from "../Components/RecentPosts";
import api from "../Components/apiService";
import "../Styles/About.css";
import Logo from "../Images/logo.png";

function About() {
  const [usersCount, setUsersCount] = useState(0);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await api.get('user/count');
        setUsersCount(response.totalUsers);
      } catch (error) {
        console.error('Failed to get total number of users:', error);
      }
    };
    
    fetchTotalUsers();
  }, []);

  const highlightRow = (min, max) => {
    return usersCount >= min && usersCount <= max;
  };

  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="about mt-5">
            <div className="base-card">
            <h1>
              <img src={Logo} className="about_img"></img><br/>
              Deteriora.com
            </h1>
            <br />

            <h2>What is Deteriora?</h2>
            <p>
              Deteriora is a community where users can share <strong>Links</strong> or create <strong>Text</strong> posts to spark engaging conversations. Whether you've stumbled upon an intriguing article or you have a burning question, Deteriora is your go-to platform to share and discuss.
            </p>
            <br />

            <h2>Voting Power</h2>
            <p>
              Every member has the power to express their opinion on posts and comments through our voting system. Initially, every user's vote has a power of <strong>1</strong>. This means that when you upvote or downvote, you either add 1 or subtract 1 from the score of that particular post or comment.
            </p>
            <p>
              However, as our community grows, the power of a single vote declines, as represented in our voting power chart.
            </p>
            <br />


            <h2>Why Voting Power Declines</h2>
            <p>
              As platforms gain traction and popularity, they often witness a surge in new users joining not primarily because of genuine interest in the content or community, but because it's trending or seen as the "next big thing". While growth is exciting, this influx can sometimes lead to a dilution in the quality of content and a shift in community values.
            </p>
            <p>
              At Deteriora, we recognize this challenge. By adjusting the voting power over time, we aim to maintain the integrity and authenticity of our community. As the community grows, we want to ensure that the voices shaping the content and conversations truly resonate with the core ethos of Deteriora. This progressive decrease in individual voting power ensures that popularity doesn't overshadow genuine quality.
            </p>
            <br />

            <h2>Account Creation</h2>
            <p>
              Creating an account with Deteriora is initially free. But as our family expands and crosses the <strong>10,000 users</strong> mark, a one-time fee will be required to create a new account.
            </p>
            <br />

            <h2>If You're Invited Your Account is Free</h2>
            <p>
              If you've received an invitation from a current member, you can join Deteriora's community without paying any fee. Diving into the world of Deteriora for free!
            </p>
            <br />

            <h2>Why the Fee?</h2>
            <p>
                In the ever-evolving digital landscape, it's becoming increasingly essential to carve out spaces that prioritize meaningful engagement over sheer numbers. Deteriora was designed with this very intention in mind. The account creation fee, which might seem unusual at first glance, is an embodiment of this vision. Let's delve into the reasons behind this choice and its advantages:
                </p>
                <p>
                    Our mission revolves around crafting a space of genuine engagement. The fee is an instrumental part of this endeavor, and here's why:
                </p>
                <ol>
                    <li>
                        <strong>Preserving Integrity:</strong> The digital world is vast, and while numbers are impressive, we prioritize quality. A fee ensures members are genuinely invested in contributing meaningfully, free from the usual clutter of trolls and spam.<br /><br />
                    </li>
                    <li>
                        <strong>Promoting Accountability:</strong> By choosing to join us, you're demonstrating a commitment to this community. In return, we promise an environment that respects and values your engagement, unmarred by disruptive behaviors.<br /><br />
                    </li>
                    <li>
                        <strong>Valuing Every Voice:</strong> We wish for every voice in Deteriora to be consistent and truly engaged. The fee discourages the creation of disposable accounts, ensuring every participant is recognized and valued.<br /><br />
                    </li>
                    <li>
                        <strong>Upholding Community Health:</strong> We're dedicated to your positive experience. By introducing a fee, we deter users with short-term or harmful intentions, ensuring a safe, constructive community for all.<br /><br />
                    </li>
                </ol>
                <p>
                    Your experience at Deteriora is at the heart of our decisions. This fee represents a mutual pledge - your commitment to engage and our promise to maintain an environment worth your investment. Together, we can ensure Deteriora stands out as a beacon of meaningful interactions in the digital realm.
                </p>
            </div>

              <div className="base-card mt-3">

                <h3 className="user-count">Current Number of Users: <span>{usersCount}</span></h3>
                <table>

                <thead>
                  <tr>
                    <th>Number of Users</th>
                    <th>Voting Power</th>
                    <th>Fee</th>
                  </tr>
                </thead>
                <tbody>
                <tr className={highlightRow(0, 9999) ? 'highlight-row' : ''}>
                  <td>Less than 10,000</td>
                  <td>1.00</td>
                  <td>Free</td>
                </tr>
                <tr className={highlightRow(10000, 19999) ? 'highlight-row' : ''}>
                  <td>10,000 - 19,999</td>
                  <td>0.80</td>
                  <td>$1</td>
                </tr>
                <tr className={highlightRow(20000, 29999) ? 'highlight-row' : ''}>
                  <td>20,000 - 29,999</td>
                  <td>0.70</td>
                  <td>$2</td>
                </tr>
                <tr className={highlightRow(30000, 49999) ? 'highlight-row' : ''}>
                  <td>30,000 - 49,999</td>
                  <td>0.60</td>
                  <td>$3</td>
                </tr>
                <tr className={highlightRow(50000, 79999) ? 'highlight-row' : ''}>
                  <td>50,000 - 79,999</td>
                  <td>0.40</td>
                  <td>$5</td>
                </tr>
                <tr className={highlightRow(80000, 129999) ? 'highlight-row' : ''}>
                  <td>80,000 - 129,999</td>
                  <td>0.30</td>
                  <td>$7</td>
                </tr>
                <tr className={highlightRow(130000, 209999) ? 'highlight-row' : ''}>
                  <td>130,000 - 209,999</td>
                  <td>0.20</td>
                  <td>$10</td>
                </tr>
                <tr className={highlightRow(210000, 299999) ? 'highlight-row' : ''}>
                  <td>210,000 - 299,999</td>
                  <td>0.15</td>
                  <td>$13</td>
                </tr>
                <tr className={highlightRow(300000, 499999) ? 'highlight-row' : ''}>
                  <td>300,000 - 499,999</td>
                  <td>0.10</td>
                  <td>$16</td>
                </tr>
                <tr className={highlightRow(500000, 999999) ? 'highlight-row' : ''}>
                  <td>500,000 - 999,999</td>
                  <td>0.05</td>
                  <td>$20</td>
                </tr>
                <tr className={highlightRow(1000000, Infinity) ? 'highlight-row' : ''}>
                  <td>1,000,000 and above</td>
                  <td>0.01</td>
                  <td>$25</td>
                </tr>
                </tbody>
                </table>
              </div>
              {/* <div className="base-card about-alert mt-3">
                <h3>But be warned</h3>
                <p>
                  If you invite someone, and they get reported/banned, both you
                  and all invitees will lose their accounts. Plus, if an invitee
                  of yours invites someone who gets banned, you will get a
                  strike. Three strikes result in loss of account. No refunds
                  will be given!
                </p>
              </div>
              <div className="base-card about-alert mt-3">
                <h3>Invitation-Based Membership: Understand the Risks</h3>
                <p>
                  Please note that if you join Deteriora through an invitation,
                  your account's standing is tied not only to your behavior but
                  also to the actions of your inviter and anyone else in your
                  invitation hierarchy. If any user in this chain is banned, all
                  linked accounts down the line, including yours, could also be
                  deactivated. This chain effect also applies to anyone you
                  invite. Hence, accepting an invitation carries inherent risk.
                  Make sure you trust your inviter's judgement and their
                  understanding of our community guidelines. Similarly, exercise
                  caution when inviting others. Remember, you carry
                  responsibility for their behavior on Deteriora. Join and
                  invite responsibly.
                </p>
              </div> */}

              <div className="base-card mt-3">
                <h3>Deteriora Code of Conduct and Behavior Expectations</h3>
                <p>
                    At Deteriora, our goal is to create a respectful and inclusive environment for all members. We aim to foster conversations and exchanges that are positive, constructive, and unbiased.
                </p>
                <h4>Conduct Guidelines</h4>
                <p>
                    Certain behaviors are considered unacceptable and may lead to disciplinary actions or removal from our platform:
                </p>
                <ol>
                    <li>
                        <strong>Respectful Interaction:</strong> Avoid personal insults, rudeness, and always communicate with fellow members with courtesy and understanding.
                    </li>
                    <li>
                        <strong>No Inappropriate Content:</strong> Sharing or linking to websites with inappropriate, offensive, or harmful content is strictly prohibited.
                    </li>
                    <li>
                        <strong>Avoid Spam:</strong> Ensure that all contributions are meaningful and relevant. Avoid repetitively posting the same content or promoting services/products that aren't related to our community's interests.
                    </li>
                    <li>
                        <strong>Neutral Language:</strong> Refrain from using biased or emotionally charged headlines and comments. For example, instead of "Dumb politician gets caught stealing", use "Politician caught stealing", or instead of "This is awesome, Netflix is allowing password sharing!", use "Netflix continues to allow password sharing."
                    </li>
                </ol>
                <p>
                    We sincerely thank every member for their understanding, cooperation, and contribution towards making Deteriora a positive and enlightening space for everyone.
                </p>
            </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Welcome To Deteriora</h4>
                <span>share links and engage in discussions</span>
                <Link className="btn btn-1" to="/">
                  Explore Deteriora
                </Link>
              </div>
            </div>
            {/* <RecentPosts /> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
