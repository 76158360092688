import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Styles/Post.css";
import ProfileBanner from "../Images/banner.png";
import RecentPosts from "../Components/RecentPosts";
import api from "../Components/apiService";
import Voting from "../Components/voting";

import Comment from "../Components/Comment";
function decodeHtmlEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}
function Post({ isLoggedIn, showLogin }) {
  const { postID } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [reportModal, showReportModal] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);

  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`authenticate/status`);
        setCurrentUser(response);
        setIsLoading(false); // <-- Set isLoading to false after fetching
      } catch (error) {
        console.error("Fetching user failed:", error);
        setIsLoading(false); // <-- Also set it to false on error for good measure
      }
    };

    const fetchPost = async () => {
      try {
        const response = await api.get(`post/get/${postID}`);
        setPost(response);
      } catch (error) {
        console.error("Fetching post failed:", error);
      }
    };

    const fetchComments = async () => {
      try {
        const response = await api.get(`comment/post/${postID}`);
        if (Array.isArray(response.data)) {
          setComments(response.data);
        } else {
          setComments([]);
        }
      } catch (error) {
        console.error("Fetching comments failed:", error);
      }
    };

    fetchUser();
    fetchPost();
    fetchComments();
  }, [postID]);

  const postComment = async () => {
    const textareaId = replyingTo ? `reply-text-${replyingTo}` : "comment-text";
    const commentText = document.getElementById(textareaId).value;

    // Check if commentText is empty and if so, make the border red
    if (!commentText.trim()) {
      document.getElementById(textareaId).style.border = "1px solid red";
      return; // Exit the function without posting if there's no text
    }
    if (commentText) {
      try {
        const response = await api.post("comment/create", {
          postID: postID.toString(),
          text: commentText,
          parentCommentID: replyingTo || "",
        });

        const newComment = response.data; // Assuming response.data is the new comment

        setReplyingTo(null);

        if (replyingTo) {
          // It's a reply, find the correct parent and add the reply to it
          setComments((prevComments) => {
            if (!Array.isArray(prevComments)) {
              console.error(
                "Expected comments to be an array but received:",
                prevComments
              );
              return [];
            }

            const clonedComments = JSON.parse(JSON.stringify(prevComments)); // Deep clone
            insertReply(clonedComments, replyingTo, newComment);
            return clonedComments;
          });
        } else {
          // It's a top-level comment, add to the list
          setComments((prevComments) => {
            if (!Array.isArray(prevComments)) {
              console.error(
                "Expected comments to be an array but received:",
                prevComments
              );
              return [newComment];
            }

            return [...prevComments, newComment];
          });
        }

        document.getElementById("comment-text").value = "";
      } catch (error) {
        console.error("Posting comment failed:", error);
      }
    }
  };

  const insertReply = (commentList, targetCommentID, newReply) => {
    for (let comment of commentList) {
      // If the current comment is the one we're replying to
      if (comment.commentID === targetCommentID) {
        comment.replies = [...(comment.replies || []), newReply];
        return true;
      }

      // Otherwise, if the comment has replies, search within them
      if (comment.replies && comment.replies.length > 0) {
        if (insertReply(comment.replies, targetCommentID, newReply)) {
          return true;
        }
      }
    }

    return false;
  };

  const renderComment = (comment) => (
    <React.Fragment key={comment.commentID}>
      {!isLoading && (
        <Comment
          comment={comment}
          postID={postID}
          setComments={setComments}
          replyingTo={replyingTo}
          setReplyingTo={setReplyingTo}
          insertReply={insertReply}
          postComment={postComment}
          showLogin={showLogin}
          isLoggedIn={isLoggedIn}
        />
      )}
    </React.Fragment>
  );

  return post ? (
    <>
      {/* <div className="container-fluid close-post">
        <div className="container-xs ps-5">
          <div className="close-post-details">
              <div className="icons" >
                <i className={`voting fal fa-up ${post.upVoted}`}></i> <span className="up-votes">{post.upvotes}</span>
              </div>
              <div className="icons">
                <i className={`voting fal fa-down ${post.downVoted}`}></i> <span className="down-votes">{post.downvotes}</span>
              </div>
            <h4>{post.title}</h4>
            <Link className="icons" to="/">
              <i className="fal fa-x"></i>
              Close
            </Link>
          </div>
        </div>
      </div> */}
      <main
        className="container-fluid pt-5 post-page"
        style={{ marginTop: "10rem" }}
      >
        <div className="container-xs">
          <div className="row">
            <div className="col-md-8 pt-4">
              {/* -- Post -- */}
              <div className="base-card feed-post feed-post-details">
                <div className="post-header">
                  <input type="hidden" value={post.postID} id="postID" />
                  <div className="stats ms-2 ">
                    <div className={`genre ${post.category.link}`}>
                      {post.category.name}
                    </div>
                    <span className="ms-auto report-icon">{post.date}</span>
                  </div>
                </div>
                <div className="post-body">
                  <h4>
                    <Link to={post.url} className="postViewURL">
                      {decodeHtmlEntities(post.title)}
                    </Link>
                  </h4>
                  {<p>{decodeHtmlEntities(post.text)}</p>}
                </div>
                <div className="post-footer" postid={postID}>
                  <Voting
                    initialUpvotes={post.upvotes}
                    initialDownvotes={post.downvotes}
                    type="postID"
                    id={postID}
                    showLogin={showLogin}
                    upVoted={post.upVoted}
                    downVoted={post.downVoted}
                  />

                  <div className="icons">
                    <i className="fal fa-comment-alt"></i>
                    {}
                  </div>
                  <div className="icons">
                    <i className="fal fa-share"></i>
                    <span>Share</span>
                  </div>
                  <div
                    className="icons ms-auto report-icon"
                    onClick={() => showReportModal(true)}
                  >
                    <i className="fal fa-flag"></i>
                    <span>Report</span>
                  </div>
                </div>

                {console.log("z")}
                {console.log(currentUser)}
                {currentUser && currentUser.userID ? (
                  <div className="post-self-comment">
                    <div className="comment-box">
                      <textarea
                        id="comment-text"
                        placeholder="What are your thoughts?"
                      ></textarea>
                    </div>
                    <button
                      className="btn btn-1 post-comment"
                      onClick={postComment}
                    >
                      Comment
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {/* Coments go here */}
                <div className="comments-section">
                  {Array.isArray(comments) &&
                    comments.map((comment) => renderComment(comment))}
                </div>
              </div>
            </div>
            <div className="col-md-4 pt-4">
              <div className="base-card post-profile">
                <div className="top-background">
                  <img src={ProfileBanner} alt="Profile Banner"></img>
                </div>
                <div className="post-profile-header">
                  Posted By : &nbsp;
                  <Link
                    to={`/u/${post.user.userID}/${post.user.username}`}
                    className="profile-name"
                  >
                    {post.user.username}
                  </Link>
                </div>

                <div className="post-profile-about">
                  <span>Joined: {post.user.dateStamp}</span>
                </div>
                {/* <div className="post-profile-stats">
                  <div className="icons">
                    <i className="fas fa-fire"></i>
                    Voting Power : 1
                  </div>
                  <div className="icons">
                    <i className="fas fa-user-plus"></i>
                    Invited Users : 9
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* {reportModal ? (
        <Report
          reportModal={reportModal}
          id={post.id}
          showReportModal={showReportModal}
        />
      ) : (
        <></>
      )} */}
    </>
  ) : (
    <></>
  );
}

export default Post;
