import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../Components/apiService";

import PostCard from "../Components/PostCard";
import RecentPosts from "../Components/RecentPosts";
import "../Styles/Home.css";

function Category({ showTextPost, showUrlPost }) {

  const [categories, setCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const { category } = useParams(); // This gets the category from the URL (corrected line)
  const categoryName = category;
  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      const data = await api.get("post/categories");
      setCategories(data);
    } catch (error) {
      console.error("Fetching categories failed:", error);
    }
  };

  const getPosts = async () => {
    try {
      const data = await api.get("post/hot", {category: categoryName});
      setPosts(data);
    } catch (error) {
      console.error("Fetching posts failed:", error);
    }
  };

  useEffect(() => {
    getCategories();
    getPosts();
  }, [categoryName]);

  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="create-post mt-5">
              <select 
                value={`/r/${categoryName}`} 
                onChange={e => navigate(e.target.value)}>
                <option value='/'>All</option>
                {categories.map((category) => (
                  <option key={category.link} value={`/r/${category.link}`}>{category.name}</option>
                ))}
              </select>
              <button
                className="post-type ms-auto"
                id="textPostModalBtn"
                onClick={() => showTextPost(true)}
              >
                <i className="fal fa-text"></i>
              </button>
              <button
                className="post-type"
                id="urlPostModalBtn"
                onClick={() => showUrlPost(true)}
              >
                <i className="fal fa-link"></i>
              </button>
            </div>
            {/* Dynamic Posts */}
            {posts.map((post) => (
              <PostCard
                key={post.postID}
                postID={post.postID}
                url={post.url}
                username={post.user.username}
                userID={post.user.userID}
                score={post.score}
                genre={post.category.name}
                genre_class={post.category.link}
                date={post.dateStamp}
                title={post.title}
                upvotes={post.upvotes}
                comments={post.commentCount}
                downvotes={post.downvotes}
                upVoted={post.upVoted}
                downVoted={post.downVoted}
                ago={post.ago}
              />
            ))}
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Deteriora Membership</h4>
                <span>Free For First 1000 Deteriora Memebers.</span>
                <button className="btn btn-1">Learn More</button>
              </div>
            </div>
            {/* <RecentPosts /> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Category;
