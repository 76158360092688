import { React, useEffect, useRef, useState } from 'react';
import "../../Styles/Modal.css";
import { Link } from 'react-router-dom';
import { authService } from '../authService';
import api from '../apiService';

function Login({ login, showLogin, showSignUp}) {
    const loginRef = useRef();
    const closeRef = useRef();
    const [validationErrors, setValidationErrors] = useState({ username: false, password: false });

    const handleForgotPasswordClick = () => {
        // Close the login modal when "Forgot Password?" is clicked
        showLogin(false);
    };
    
    // If the inbox modal is open and user clicks outside of it then close the modal
    useEffect(() => {
        const clickOutside = e => {
            if (login && loginRef.current && !loginRef.current.contains(e.target) && !closeRef.current.contains(e.target)) {
                showLogin(false);
            }
        }
        document.addEventListener("mousedown", clickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", clickOutside);
        }
    }, [login]);

    const handleModalSwitch = () => {
        showLogin(false);
        showSignUp(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const username = e.target.elements[0].value;
        const password = e.target.elements[1].value;

        const newValidationErrors = {
            username: !username,
            password: !password,
        };

        setValidationErrors(newValidationErrors);

        if (!newValidationErrors.username && !newValidationErrors.password) {
            const data = await api.post('authenticate/login', { username, password });

            if (data.message) {
                alert(data.message);
            } else if (data.newAccessToken && data.newRefreshToken) {
                authService.setTokens(data.newAccessToken, data.newRefreshToken);
                window.location.reload();
            } else {
                alert('An error occurred. Please try again later.');
            }
        }
    }

    return (
        <div id="signUpModal" className="modal">
            <div className="modal-content" ref={loginRef} style={{ width: "50rem" }}>
                <span className="close" ref={closeRef} onClick={() => showLogin(false)}><i className="fal fa-times"></i></span>
                <h4>Log In To Deteriora</h4>
                <div className="bar"></div>
                <div className="signup-form">
                    <form onSubmit={handleSubmit}>
                        <div className={`f-control ${validationErrors.username ? 'error' : ''}`}>
                            <label><i className="fal fa-user"></i></label>
                            <input type="text" placeholder="Username"></input>
                            <div className="border"></div>
                        </div>
                        <div className={`f-control ${validationErrors.password ? 'error' : ''}`}>
                            <label><i className="fal fa-key"></i></label>
                            <input type="password" placeholder="Password"></input>
                            <div className="border"></div>
                        </div>
                        <div className="signup-footer">
                            <p className="ms-2" id="sign-in" onClick={handleModalSwitch}>No Account?</p>
                            <Link to="/forgot" onClick={handleForgotPasswordClick}>Forgot Password?</Link>
                            <button type="submit" className="btn btn-1 mt-2">Log In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;