import React, { useEffect, useRef, useState } from "react";
import "../Styles/Navbar.css";
import { useNavigate, Link } from "react-router-dom";
import api from "./apiService"; // Assuming you've structured it this way
import { authService } from "./authService"; // Assuming you've structured it this way
import Logo from "../Images/logo.png";

function NavBar({
  isLoggedIn,
  setIsLoggedIn,
  showTextPost,
  showUrlPost,
  showSignUp,
  showLogin,
}) {
  const [menu, showMenu] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userID, setUserID] = useState(null);
  const [votingPower, setUserVotingPower] = useState(0);

  const menuRef = useRef();
  const profileRef = useRef();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const data = await api.post("authenticate/logout");

      if (data.message === true) {
        authService.logout();
        setIsLoggedIn(false); // Update the state
        window.location = "/";
      } else {
        alert("Error during logout. Please try again.");
        console.error("Error during logout.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  useEffect(() => {
    // Function to check user's login status and fetch details
    const checkLoginStatus = async () => {
      try {
        const response = await api.get("authenticate/status");
        if (response.isLoggedIn) {
          setIsLoggedIn(true);
          setUserName(response.username);
          setUserID(response.userID);
          setUserVotingPower(response.votingPower);
        } else {
          setIsLoggedIn(false);
          setUserName(null);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    const clickOutside = (e) => {
      if (
        menu &&
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        !profileRef.current.contains(e.target)
      ) {
        showMenu(false);
      }
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [menu]);

  return (
    <header className="container-fluid">
      <nav className="container">
        <a className="logo" onClick={() => navigate("/")}>
          <img className="logo_img" src={Logo}></img>eteriora
        </a>
        <div className="search-bar">
          <input type="text" placeholder="Search Deteriora"></input>
          <div className="search-icon">
            <i className="fal fa-search"></i>
          </div>
        </div>
        {/* 
        <div className="inbox" id="inboxModalBtn">
          onClick={() => showInbox(true)}
          <i className="fal fa-comment-dots"></i>
        </div>
        */}
        <button
          className="post-type-link"
          //check if logged in
          onClick={() => (isLoggedIn ? showUrlPost(true) : showLogin(true))}
        >
          <i className="fal fa-link"></i>
        </button>
        <button
          className="post-type-text"
          id="textPostModalBtn"
          //check if logged in
          onClick={() => (isLoggedIn ? showTextPost(true) : showLogin(true))}
        >
          <i className="fal fa-text"></i>
        </button>
        <div
          className="profile"
          id="navbar"
          ref={profileRef}
          onClick={() => showMenu(!menu)}
        >
          <div className="avatar">
            {isLoggedIn && userName ? userName[0].toUpperCase() : "👤"}
          </div>
          <div
            className="dropdown"
            ref={menuRef}
            style={{ display: menu ? "block" : "none" }}
            id="profileDropdown"
          >
            <input
              id="currentUserID"
              type="hidden"
              value={isLoggedIn && userID ? userID : ""}
            />
            <input
              id="currentUsername"
              type="hidden"
              value={isLoggedIn && userName ? userName : ""}
            />
            <input
              id="currentUserVotingPower"
              type="hidden"
              value={isLoggedIn && votingPower ? votingPower : "0"}
            />
            <div className="arrow-top"></div>
            <div className="dropdown-content">
              <ul>
                {isLoggedIn && (
                  <>
                    <li id="usernameNav">
                      <Link to={"/u/" + userID + "/" + userName}>
                        {userName}
                      </Link>
                    </li>
                    <li>
                      <Link to="/EditProfile">Settings</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link onClick={handleLogout}>Logout</Link>
                    </li>
                  </>
                )}
                {!isLoggedIn && (
                  <>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li id="signUpModalBtn" onClick={() => showSignUp(true)}>
                      Sign Up
                    </li>
                    <li id="loginModalBtn" onClick={() => showLogin(true)}>
                      Log In
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
