import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../Components/apiService";

import PostCard from "../Components/PostCard";
import RecentPosts from "../Components/RecentPosts";
import "../Styles/Home.css";

function Home({ isLoggedIn, showTextPost, showUrlPost, showLogin }) {
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const data = await api.get("post/categories"); // Assuming this is your endpoint for fetching categories
      setCategories(data);
    } catch (error) {
      console.error("Fetching categories failed:", error);
    }
  };

  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    try {
      const data = await api.get("post/hot");
      setPosts(data);
    } catch (error) {
      console.error("Fetching posts failed:", error);
    }
  };

  useEffect(() => {
    getCategories();
    getPosts();
  }, []);

  return (
    <main className="container-fluid">
      <div className="container-xs">
        <div className="row">
          <div className="col-md-8">
            <div className="create-post mt-5">
              <select 
                onChange={(e) => navigate(e.target.value)}
              >
                <option value="/">All</option>
                {categories.map((category) => (
                  <option key={category.link} value={'/r/'+category.link}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className="home-sort-posts">
                <select>
                  <option value="recent">Newest</option>
                  <option value="upvotes">Upvotes</option>
                </select>
              </div>
            </div>
            {/* Dynamic Posts */}
            {posts.map((post) => (
              <PostCard
                key={post.postID}
                postID={post.postID}
                url={post.url}
                username={post.user.username}
                userID={post.user.userID}
                score={post.score}
                genre={post.category.name}
                genre_class={post.category.link}
                date={post.dateStamp}
                title={post.title}
                upvotes={post.upvotes}
                comments={post.commentCount}
                downvotes={post.downvotes}
                upVoted={post.upVoted}
                downVoted={post.downVoted}
                ago={post.ago}
                showLogin={showLogin}
              />
            ))}
          </div>
          <div className="col-md-4">
            <div className="base-card membership mt-5">
              <div className="membership-icon">
                <i className="far fa-bullhorn"></i>
              </div>
              <div className="membership-content">
                <h4>Deteriora Membership</h4>
                <span>Free For First 10,000 Deteriora Memebers.</span>
                <Link to={`/About`}>
                  <button className="btn btn-1">Learn More</button>
                </Link>
              </div>
            </div>
            {/* <RecentPosts /> */}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
