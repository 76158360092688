import { React, useEffect, useRef, useState } from 'react';
import "../../Styles/Modal.css";

function Alert({ alert, showAlert}) {
    const modalRef = useRef();
    const closeRef = useRef();

    // If the modal is open and user clicks outside of it then close the modal
    useEffect(() => {
        const clickOutside = e => {
            if (alert && modalRef.current && !modalRef.current.contains(e.target) && !closeRef.current.contains(e.target)) {
                showAlert(false);
            }
        }
        document.addEventListener("mousedown", clickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", clickOutside);
        }
    }, [alert]);

    return (
        <div id="alertModal" className="modal">
            <div className="modal-content" ref={modalRef} style={{ width: "50rem" }}>
                <span className="close" ref={closeRef} onClick={() => showAlert(false)}><i className="fal fa-times"></i></span>
                <h4>You are not logged in!</h4>
            </div>
        </div>
    );
}

export default Alert;