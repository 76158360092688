import { React, useEffect, useRef } from "react";
import "../../Styles/Modal.css";

function Report({ reportModal, showReportModal }) {
  const reportModalRef = useRef();
  const closeRef = useRef();

  // If the text post modal is open and user clicks outside of it then close the modal
  useEffect(() => {
    const clickOutside = (e) => {
      if (
        reportModal &&
        reportModalRef.current &&
        !reportModalRef.current.contains(e.target) &&
        !reportModalRef.current.contains(e.target)
      ) {
        showReportModal(false);
      }
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [reportModal]);

  return (
    <div id="reportModal" className="modal">
      <div
        className="modal-content"
        ref={reportModalRef}
        style={{ width: "50rem" }}
      >
        <span
          className="close"
          ref={closeRef}
          onClick={() => showReportModal(false)}
        >
          <i className="fal fa-times"></i>
        </span>
        <h4>Submit A Report</h4>
        <div className="bar"></div>
        <div className="signup-form">
          <span className="d-inline-block">
            Thanks for looking out for yourself and your fellow members by
            reporting things that break the rules. Let us know what's happening,
            and we'll look into it.
          </span>
          <div className="bar"></div>
          <form>
            <div className="f-control">
              <label>
                <i className="fal fa-ban"></i>
              </label>
              <textarea type="text" placeholder="Report Description"></textarea>
              <div className="border"></div>
            </div>
            <div className="signup-footer">
              <button type="submit" className="btn btn-1 mt-2 me-auto">
                Report
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Report;
