import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Voting from "./voting";
import EditIcon from "../Images/edit.png";
import DeleteIcon from "../Images/delete.png";
import api from "./apiService"; 

function Comment({
  comment,
  postID,
  setComments,
  replyingTo,
  setReplyingTo,
  insertReply,
  postComment,
  showLogin,
  currentUser,
  isLoggedIn,
  upVoted,
  downVoted
}) {
  
  const [showReply, setShowReply] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [commentText, setCommentText] = useState(comment.text);
  const [confirmDelete, setConfirmDelete] = useState(false);

  function decodeHtmlEntities(text) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setCommentText(comment.text);
  };

  const handleUpdate = async () => {
    try {
      const response = await api.post("comment/update", {
        commentID: comment.commentID,
        text: commentText,
      });

      if (response && response.success !== undefined) {
        setEditMode(false);
      } else {
        console.error("Error updating comment");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await api.post("comment/delete", {
        commentID: comment.commentID,
      });

      if (response && response.success !== undefined) {
        const element = document.getElementById(comment.commentID);
        if (element) {
            element.remove();
        }
      } else {
        console.error("Error deleting comment:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const nestedComments = (comment.replies || []).map((reply) => (
    <Comment
      key={reply.commentID}
      comment={reply}
      postID={postID}
      setComments={setComments}
      replyingTo={replyingTo}
      setReplyingTo={setReplyingTo}
      insertReply={insertReply}
      postComment={postComment}
      showLogin={showLogin}
      currentUser={currentUser}
      isLoggedIn={isLoggedIn}
      upVoted={upVoted}
      downVoted={downVoted}
    />
  ));

  const cancelReply = () => {
    setShowReply(false);
  };

  const handleReply = () => {
    setShowReply(true);
    setReplyingTo(comment.commentID);
  };

  const postReply = () => {
    postComment();
    setShowReply(false);
  };

  return (
    <div className="comment-set">
      <CommentContainer className="comment"  id={comment.commentID}>
        <div className="comment-header">
          <div className="profile-name">
            <Link
              to={`/u/${comment.user.userID}/${comment.user.username}`}
              className="profile-name"
            >
              {comment.user.username}
            </Link> 
             {" " + comment.ago}
          </div>
        </div>
        <div className="comment-body">
          {editMode ? (
            <EditContainer>
              <textarea value={commentText} onChange={(e) => setCommentText(e.target.value)} />
              <div className="btn-container">
                <button onClick={handleUpdate}>Update</button>
                <button onClick={handleCancel}>Cancel</button>
              </div>
            </EditContainer>
          ) : (
            decodeHtmlEntities(commentText)
          )}
        </div>
        <div className="comment-footer">
          <Voting
            initialUpvotes={comment.upvotes}
            initialDownvotes={comment.downvotes}
            type="commentID"
            id={comment.commentID}
            showLogin={showLogin}
            upVoted = {comment.upVoted}
            downVoted = {comment.downVoted}
          />
          <div className="icons reply-comment" onClick={handleReply}>
            <i className="fal fa-comment-alt"></i> Reply
          </div>
          {comment.showEdit !== true && (
          <div className="icons ms-auto report-icon">
            <i className="fal fa-flag"></i>
            <span>Report</span>
          </div>
          )}
          {isLoggedIn && comment.showEdit === true && (
            <>
              {!editMode && !confirmDelete && (
                <div className="icons ms-auto edit-icon" onClick={handleEdit}>
                  <img src={EditIcon} alt="Edit" />
                </div>
              )}
              {!confirmDelete ? (
                <div className="icons delete-icon" onClick={() => setConfirmDelete(true)}>
                  <img src={DeleteIcon} alt="Delete" />
                </div>
              ) : (
                <ConfirmDeleteContainer className="confirm-delete ms-auto">
                  <span>Delete?</span>
                  <button onClick={handleDelete}>Yes</button>
                  <button onClick={() => setConfirmDelete(false)}>No</button>
                </ConfirmDeleteContainer>
              )}
            </>
          )}
        </div>
      </CommentContainer>
      {showReply && (
        <div className="reply-section">
          <textarea
            id={`reply-text-${comment.commentID}`}
            placeholder="Reply to the comment..."
          ></textarea>
          <div className="reply-buttons">
            <button
              className="btn btn-1"
              onClick={cancelReply}
            >
              Cancel
            </button>
            <button
              className="btn btn-1 post-comment"
              onClick={postReply}
            >
              Reply
            </button>
          </div>
        </div>
      )}
      <div className="reply">{nestedComments}</div>
    </div>
  );
}

const CommentContainer = styled.div`
  // You can adapt styles from ProfileCommentContainer
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  .btn-container {
    display: flex;
    gap: 0.5rem;

    button {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      background-color: #5865f2;
      color: white;

      &:hover {
        background-color: #4b56d0;
      }

      &:last-child {
        background-color: #e74c3c;

        &:hover {
          background-color: #d43c2c;
        }
      }
    }
  }
`;

const ConfirmDeleteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  span {
    font-weight: 600;
  }

  button {
    padding: 0.3rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:first-child {
      background-color: #e74c3c;
      color: white;

      &:hover {
        background-color: #d43c2c;
      }
    }

    &:last-child {
      background-color: #ccc;

      &:hover {
        background-color: #b0b0b0;
      }
    }
  }
`;

export default Comment;
